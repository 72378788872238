import {
  Container,
  Typography,
} from "@mui/material";
import AddressMap from './AddressMap'
import Banner from './Banner'
import Plans from './Plans'

const Content = () => {
  return (
    <Container>
      <Banner />
      <Typography variant="h4" gutterBottom mt={2}>Aqui na Teleredes temos a melhor conexão para você!</Typography>
      <Plans />
      <AddressMap />
    </Container>
  );
}

export default Content;