import {
  AppBar,
  Button,
  Container,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

const Header = () => {
  return (
    <AppBar>
      <Toolbar sx={{ backgroundColor: '#000' }}>
        <Container disableGutters>
          <Grid container>
            <Grid item xs={6}>
              <Stack direction='row'>
                <Typography variant='h6'>
                  Teleredes Telecom
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction='row-reverse'>
                <Button variant='contained' href='https://central.teleredestelecom.com.br'>
                  2° via boleto
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Header;