import {
  Fab,
  Grow,
  Stack,
  Toolbar,
} from '@mui/material';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function App() {
  return (
    <>
      <Header />
      <Toolbar />
      <Content />
      <Footer />
      <Stack direction='row-reverse'>
        <Grow in timeout={1000}>
          <a href='https://api.whatsapp.com/send?phone=557199414141&text=Ol%C3%A1.%20Eu%20achei%20o%20contato%20no%20site.%20Gostaria%20de%20saber%20mais%20sobre%20os%20planos.'
            target="_blank"
            rel="noopener noreferrer">
            <Fab sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
            }}
              color='success'
            >
              <WhatsAppIcon />
            </Fab>
          </a>
        </Grow>
      </Stack>
    </>
  );
}

export default App;
