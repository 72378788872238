import Box from '@mui/material/Box'

const AddressMap = () => {
  return (
    <Box sx={{ mt: '2em' }}>
      <iframe title='Google Maps Address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.187226664829!2d-38.327086485178825!3d-12.895679490905705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd8ad45cb0f3c6ca5!2zMTLCsDUzJzQ0LjUiUyAzOMKwMTknMjkuNiJX!5e0!3m2!1spt-BR!2sbr!4v1657740242845!5m2!1spt-BR!2sbr" width="100%" height="250" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </Box>
  )
}

export default AddressMap