import {
  Grow,
  Paper,
} from '@mui/material'

const Banner = () => {
  return (
    <Paper sx={{ marginTop: '2em', textAlign: 'center' }}>
      <Grow in timeout={500}>
        <img src='/logo_white.jpg' alt='Logo' width='50%' />
      </Grow>
    </Paper>
  )
}

export default Banner