import {
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";

const Footer = () => {
  return (
    <Container sx={{marginTop: '2em'}}>
      <Grid container>
        <Grid item xs={4}>
          <Stack>
            <Typography>Escritório 71 9941-4141</Typography>
            <Typography>Financeiro 71 8141-4141</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{textAlign: 'center'}}>
          <Typography>Teleredes Telecom &#169; {new Date().getFullYear()}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction='row-reverse'>
            <Typography>Rua Romualdo de Brito, 06, Centro - 42700000</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;