import {
  Grid,
  Grow,
  Typography,
} from '@mui/material'
import PlanCard from './PlanCard'

const plans = [
  {
    plan: '50M',
    description: 'Internet Fibra Ótica',
    price: '69,90',
  },
  {
    plan: '100M',
    description: 'Internet Fibra Ótica',
    price: '79,90',
  },
  {
    plan: '200M',
    description: 'Internet Fibra Ótica',
    price: '89,90',
    main: true
  },
  {
    plan: '400M',
    description: 'Internet Fibra Ótica',
    price: '99,90',
  },
]


const Plans = () => {
  return (
    <>
      <Typography variant='h4' gutterBottom>Planos Fibra Ótica</Typography>

      <Grid container spacing={2}>
        {plans.map(({ plan, description, price, main }, index) => {
          return (
            <Grow in timeout={index * 500}>
              <Grid item xs={12} sm={6} md={3}>
                <PlanCard
                  plan={plan}
                  price={price}
                  description={description}
                  main={main}
                />
              </Grid>
            </Grow>
          )
        })}
      </Grid>

      <Typography variant='h4' gutterBottom mt={2}>Planos Cabo</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={3} md={4} />
        <Grow in timeout={2200}>
          <Grid item xs={12} sm={6} md={4}>
            <PlanCard
              plan='50M'
              price='69,90'
              description='Internet via cabo'
            />
          </Grid>
        </Grow>
        <Grid item xs={4} sm={3} md={4} />
      </Grid>
    </>
  );
};

export default Plans