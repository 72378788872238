import {
  Card,
  CardContent,
  Typography,
} from '@mui/material'

const PlanCard = props => {
  return (
    <Card raised={props.main}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant='h3' gutterBottom>{props.plan}</Typography>
        <Typography variant='body1' gutterBottom>{props.description}</Typography>
        <Typography variant='h5'>R$ {props.price}</Typography>
      </CardContent>
    </Card>
  )
}

export default PlanCard